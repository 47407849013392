import appConfig from '@nerdwallet/app-config';

import BankingIcon from '@nerdwallet/react-icon/nerdwallet-brand/Banking';
import CreditCardIcon from '@nerdwallet/react-icon/nerdwallet-brand/CreditCard';
import InsuranceIcon from '@nerdwallet/react-icon/nerdwallet-brand/Insurance';
import InvestmentIcon from '@nerdwallet/react-icon/nerdwallet-brand/Investment';
import MortgageIcon from '@nerdwallet/react-icon/nerdwallet-brand/Mortgage';
import PersonalLoansIcon from '@nerdwallet/react-icon/nerdwallet-brand/PersonalLoans';
import BusinessIcon from '@nerdwallet/react-icon/nerdwallet-brand/Business';
import StudentLoansIcon from '@nerdwallet/react-icon/nerdwallet-brand/StudentLoans';
import TravelIcon from '@nerdwallet/react-icon/nerdwallet-brand/Travel';

const BANKING = {
  id: 'banking',
  title: 'Banking',
  icon: BankingIcon,
  subtitle: 'Find a bank for the way you save',
  description:
    'Keep your cash in a bank account that fits your needs.  Compare options and dig into the details with objective reviews that help you make smart decisions.',
  primaryButtonText: 'compare bank accounts',
  primaryButtonUrl: '/best/banking/high-yield-online-savings-accounts',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/banking',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-banking-desktop.png`,
};

const CREDIT_CARD = {
  id: 'credit_cards',
  title: 'Credit cards',
  icon: CreditCardIcon,
  subtitle: 'The right credit card is right here',
  description:
    'Looking for a card that earns travel points, cash back, or rewards? Our side-by-side comparisons and objective reviews can help you make the smartest decision.',
  primaryButtonText: 'compare credit cards',
  primaryButtonUrl: '/the-best-credit-cards',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/credit-cards',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-cc-bg-desktop.png`,
};

const INSURANCE = {
  id: 'insurance',
  title: 'Insurance',
  icon: InsuranceIcon,
  subtitle: 'Discover the right insurance for you',
  description:
    'Compare and find insurance for your life, home, car, and more. Our comparisons and objective reviews can help you decide on the smartest insurance carriers for you.',
  primaryButtonText: 'compare car insurance',
  primaryButtonUrl: '/p/insurance/car-insurance',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/insurance',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-insurance-desktop-v4.png`,
};

const INVESTING = {
  id: 'investing',
  title: 'Investing',
  icon: InvestmentIcon,
  subtitle: 'Zero in on investments that fit you',
  description: `Whether you're looking at stocks, IRAs, or crypto, our comparisons and objective reviews can help you make strategic decisions about your next investment accounts.`,
  primaryButtonText: 'compare online brokers',
  primaryButtonUrl: '/best/investing/online-brokers-for-stock-trading',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/investing',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-investing-desktop-v3.png`,
};

const MORTGAGES = {
  id: 'mortgages',
  title: 'Mortgages',
  icon: MortgageIcon,
  subtitle: 'Home loans that match your goals',
  description:
    'Buying or refinancing a home? Our rate comparisons, mortgage insights, and objective lender reviews can help you make the smartest home-financing decision.',
  primaryButtonText: 'compare mortgage quotes',
  primaryButtonUrl: '/best/mortgages/mortgage-lenders',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/mortgages',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-mortgages-desktop-v2.png`,
};

const PERSONAL_LOANS = {
  id: 'personal_loans',
  title: 'Personal Loans',
  icon: PersonalLoansIcon,
  subtitle: 'Borrow wisely with the right loan',
  description: `When you need a personal loan, it's smart to shop around. Our loan comparisons, objective reviews, and payment calculators make it easier to choose one with confidence.`,
  primaryButtonText: 'compare personal loans',
  primaryButtonUrl: '/best/loans/personal-loans/best-personal-loans',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/personal-loans',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-loans-desktop-v3.png`,
};

const SMALL_BUSINESS = {
  id: 'small_business',
  title: 'Small Business',
  icon: BusinessIcon,
  subtitle: 'Your right fit for financing is here',
  description:
    'Find and compare SBA loans, invoice financing, and other options for your business. Personalized matches and objective reviews help you make the smartest decision.',
  primaryButtonText: 'compare business loans',
  primaryButtonUrl: '/small-business-loans',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/small-business',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-small-biz-desktop.png`,
};

const STUDENT_LOANS = {
  id: 'student_loans',
  title: 'Student Loans',
  icon: StudentLoansIcon,
  subtitle: 'Find the right student loan for you',
  description:
    'Need help paying for college? Compare offers from multiple private lenders and make smart borrowing decisions with objective reviews and loan calculators.',
  primaryButtonText: 'compare student loans',
  primaryButtonUrl: '/best/loans/student-loans/private-student-loans',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/student-loans',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-student-loans-desktop-v3.png`,
};

const TRAVEL = {
  id: 'travel',
  title: 'Travel',
  icon: TravelIcon,
  subtitle: 'Find travel products that fit your style',
  description:
    'Whether you want to travel comfortably or earn rewards, the right products are out there for you! Our straightforward comparisons and objective reviews can help you sure up your travel plans and save cash.',
  primaryButtonText: 'compare travel cards',
  primaryButtonUrl: '/recommend?primaryGoal=travel',
  secondaryButtonText: 'Explore guides and tips',
  secondaryButtonUrl: '/h/category/travel',
  backgroundImageUrl: `${appConfig.ASSETS_URL}images/homepage/vertical-travel-bg-desktop-v3.png`,
};

export const VERTICALS = {
  BANKING,
  CREDIT_CARD,
  INSURANCE,
  INVESTING,
  MORTGAGES,
  PERSONAL_LOANS,
  SMALL_BUSINESS,
  STUDENT_LOANS,
  TRAVEL,
};
